@layer components {

    /* Menu Hamburger Button */
    button.menu {
        & span {
            display: block;
            width: 60%;
            height: 4px;
            border-radius: 2px;
            position: relative;
            @apply bg-primary;
            
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                @apply bg-primary;
                left: 0;
                top: 0;
                border-radius: 2px;
                transition: 250ms ease;
            }

            &::before {
                transform: translateY(-8px)
            }
            
            &::after {
                transform: translateY(8px)
            }
        }
    }

    .nav-open {
        & nav.main-nav {
            display: block;
        }
        & button.menu {
            & span {
                background-color: transparent;

                &::before {
                    opacity: 1;
                    transform: translateY(0px) rotate(45deg);
                }
                &::after {
                    opacity: 1;
                    transform: translateY(0px) rotate(-45deg);
                    bottom: 0;
                }
            }
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
        @apply border border-gray-300 rounded shadow-sm block w-full p-2 pt-3 text-base;

        &:focus {
            @apply border-primary
        }

        &.error {
            @apply border-red-700
        }
    }

    label {
       @apply relative top-2.5 left-1.5 bg-white px-1 text-sm text-gray-700 z-10
    }
    
    [type="radio"] {
        & + label {
            @apply static top-0 left-0 font-normal text-base z-auto
        }

        &:checked + label {
            @apply bg-green-100
        }
    }

    .form-field-error {
        @apply text-sm font-bold px-2 text-red-700
    }

    .no-js {
        & .form-footer {
            display: none;
        }
        & .form-step:last-child .form-footer {
            display: flex;

            & .form-previous {
                display: none;
            }
        }
    }

    a.button, button.button {
        @apply bg-highlight text-black px-6 py-2 rounded font-bold no-underline inline-block disabled:bg-gray-200 disabled:text-gray-600;
        
        /* purgecss start ignore */
        &:hover {
            @apply transition shadow-md bg-yellow-400 disabled:bg-gray-300 disabled:text-gray-600
        }

        &:active {
            @apply ring ring-offset-2 ring-yellow-400
        }
        
        &:focus {
            @apply ring ring-offset-2 ring-yellow-300 outline-none shadow-md
        }
        /* purgecss end ignore */

        &.primary {
            @apply bg-primary text-white
        }

        &.full {
            @apply block w-full
        }

        &.large {
            @apply py-4
        }
    }


    details {
        & summary {
            position: relative;
            padding-left: 3rem;
            list-style: none;

            /* purgecss ignore */
            &::-webkit-details-marker {
                display: none;
            }
    
            &::before {
                content: "+";
                width: 3rem;
                margin: -1rem 1rem -1rem 0;
                padding: 1rem 0;
                position: absolute;
                left: 0;
                text-align: center;
                font-size: 1.5rem;
                box-sizing: border-box;
                height: 100%;
            }
        }
    
        &[open] {
            & summary {    
                &::before {
                    content: "–";
                }
            }
        }
    }

    #alert-banner {        
        & .banner {
            @apply transform transition
        }
        &.offscreen {
            @apply invisible h-0 overflow-hidden;

            & .banner {
                @apply invisible translate-y-full
            }
        }
    }

    .degree-area {
        
        @media (min-width: 1020px) {

        ul {
            display: none;
        }

        &:hover {
            & > ul {
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                width: 350px;
                min-height: 100%;
            }
        }
        
        }
    }

    .footershadow {
        box-shadow: 0 50vh 0 50vh #03122F;
    }

    .embed {
        padding-top: 56.25%;
        position: relative;
        background-position: center;
        background-size: cover;
        margin-bottom: 1.5rem;
    
        & a.play {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-indent: -9999px;
            /* background: url('../images/icon-youtube.svg') center no-repeat; */
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15%;
            opacity: 0.75;
            transition: opacity 150ms ease;
    
            &:hover {
                opacity: 1;
            }
        }
    
        & iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
    
            & + a.play {
                display: none;
            }
        }
    }

    .new-layout {
        @apply text-lg;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select {
            @apply py-3 px-4 text-base md:text-xl rounded-lg border-gray-500;

            &::placeholder {
                color: #0B0B0B;
            }

            &+.form-field-error {
                @apply mt-1 md:mt-2;
            }
        }

        details summary::before {
            @apply text-2xl md:text-4xl -mt-5;

            font-weight: 300;
        }

        .button:not(.apply) {
            @apply py-3 text-lg md:text-xl font-normal;
        }

        .nav {
            @media (min-width: 768px) {
                .nav-logo img {
                    width: 262px;
                }
            }
        }

        #hero {
            & + div, & + section {
                margin-top: 0;
                padding-top: 0;
            }
        }

        ul {
            li {
                margin-bottom: 10px;
            }
            & > li {
                &::before {
                    content: '•';
                    margin-right: 10px;

                }

                ul {
                    @apply text-base;
                    
                    margin-top: 15px;
                    padding-left: 1rem;
                    & > li {
                        &::before {
                            content: '✓';
                        }
                    }
                }
            }
        }

        footer {
            @media (min-width: 768px) {
                font-size: 21px;
                line-height: 34px;

                b, strong {
                    @apply text-2xl;
                }

                .logo {
                    width: 290px;
                }
            }
        }

        .testimonial-wrapper {
            @media (min-width: 768px) {
                max-width: 1040px;
            }
        }

        blockquote {
            p { display: inline; }

            &::before {
                content: '“';
            }
            &::after {
                content: '”';
            }

            &:not(:last-of-type) {
                margin-bottom: 1.25rem;
            }
        }

        cite {
            @apply block mt-10 lg:mt-16 text-lg lg:text-xl text-primary not-italic;
        }
    }

    .big-title {
        @apply text-3xl md:text-4xl lg:text-6xl font-bold;
    }

    .title-1 {
        @apply text-3xl md:text-40 font-bold;
    }

    .title-2 {
        @apply text-2xl md:text-30 font-bold;
    }

    .grecaptcha-badge {
        z-index: 15;
    }
}

.swiper {
    .swiper-pagination {
        @apply relative mt-6;

        .swiper-pagination-bullet {
            @apply bg-primary;
            width: 10px;
            height: 10px;
        }
    }
}