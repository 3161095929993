.container {
	padding-left: 1rem;
	padding-right: 1rem;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 1280px) {
	.container {
		max-width: 1234px;
	}
}

@media (min-width: 1536px) {
	.container {
		max-width: 1440px;
	}
}
